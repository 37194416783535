<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <a href="index.html" class="logo">
            <img src="./../assets/logo.svg" class="img" />
          </a>
          <div class="text">
            <div class="desc">{{ $parent.footerRequisites }}</div>
          </div>
          <div class="text">
            <div class="desc">{{ $t(`The Cardholder himself is responsible for compliance with the rules of the
              copyright holder`) }}.</div>
          </div>
        </div>
        <div class="right">
          <ul class="list">
            <li class="item" v-if="$parent.footerSupportEmail">
              <div class="title">{{ $t("Support") }}:</div>
              <div class="desc">{{ $parent.footerSupportEmail }}</div>
            </li>
          </ul>
        </div>
        <ul class="nav">
          <template v-for="(item, i) in $parent.nav">
            <li class="nav__item" :key="i">
              <router-link :to="item.link">
                {{ $t(item.title) }}
              </router-link>
            </li>
          </template>
        </ul>
        <ul class="nav">
          <template v-for="item in $parent.textPageList">
            <li class="nav__item" :key="item.id">
              <a @click="$parent.goToPage(item.id)">
                {{ $t(item.title) }}
              </a>
            </li>
          </template>
        </ul>

        <div class="social-links-wrapper">
          <div class="social-links">
            <a :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link">
              <img :src="link.img" class="link-img" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="right">

          <ul class="payment-methods-list list" v-if="paymentMethodsList && paymentMethodsList.length">
            <li v-for="image in allImages" :key="image" class="image-wrapper">
              <img :src="image" class="support-icon img" />
            </li>
          </ul>

          <!-- <li class="item">
              <img src="./../assets/mc_symbol.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/visa.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/3ds.png" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/methods/eps.svg" class="img" />
            </li> -->
          <!--<li class="item">
              <img src="./../assets/methods/trustly.svg" class="img" />
            </li>-->
          <!-- <li class="item">
              <img src="./../assets/methods/giropay.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/methods/blik.svg" class="img" />
            </li> -->
          <!-- <li class="item">
              <img src="./../assets/klarna.svg" class="img" />
            </li> -->
        </div>
        <div class="left">
          <div class="social-links">
            <a :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link">
              <img :src="link.img" class="link-img" />
            </a>
          </div>
          <!--            <div class="desc" v-if="$parent.footerCopyright">{{$parent.footerCopyright}}</div>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  computed: {
    paymentMethodsList() {
      if (!this.$parent.allPaymentMethods) return [];
      return this.$parent.allPaymentMethods;
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    }
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === 'card' && method.title === 'Visa/Mastercard') {
        images.push(require('@/assets/visa.svg'), require('@/assets/mc_symbol.svg'));
        images.push(require('@/assets/3ds.png'));
      } else if (method.code === 'eps' || method.title === 'EPS') {
        images.push(require('@/assets/eps.svg'));
      } else if (method.code === 'trustly' || method.title === 'Trustly') {
        images.push(require('@/assets/trustly.svg'));
      } else if (method.code === 'giropay' || method.title === 'GiroPay') {
        images.push(require('@/assets/giropay.svg'));
      } else if (method.code === 'blik' || method.title === 'Blik') {
        images.push(require('@/assets/blik.svg'));
      } else if (method.code === 'paidby' || method.title === 'Paidby') {
        images.push(require('@/assets/paidby.svg'));
      } else if (method.code === 'klarna' || method.title === 'Klarna') {
        images.push(require('@/assets/klarna.svg'));
      } else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
        images.push(require('@/assets/sofortuber.svg'));
      } else if (method.code === 'ideal' || method.title === 'iDEAL') {
        images.push(require('@/assets/ideal.svg'));
      } else if (method.code === 'bancontact' || method.title === 'Bancontact') {
        images.push(require('@/assets/bancontact.svg'));
      }
      return images;
    }
  },
  mounted() { },
};
</script>


<style lang="scss" scoped>
.footer .payment-methods-list {
  display: flex;
  gap: 10px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    max-width: 60px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}
</style>
<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div :class="['top', { active: filtersIsVisible }]">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="title">{{ $t("Filter") }}</div>
                <div class="filters-price-container">
                  <div :class="['title small', { active: !priceListIsVisible }]" @click="priceListIsVisible = !priceListIsVisible">
                    {{ $t("Price") }}
                  </div>
                  <div :class="['select-container select-container-range', { hidden: !priceListIsVisible }]">
                    <div class="val-container">
                      <div class="val min">
                        <input type="number" v-model="barMinValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                      <div class="divider">-</div>
                      <div class="val max">
                        <input type="number" v-model="barMaxValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-type-container" v-if="categoryOptions.length">
                  <div :class="['title small', { active: !typeListIsVisible }]" @click="typeListIsVisible = !typeListIsVisible">
                    {{ $t("Type") }}
                  </div>
                  <div :class="['select-container', { hidden: !typeListIsVisible }]">
                    <label class="chekbox-label" v-for="(item, i) in categoryOptions" :key="i">
                      <div class="chekbox-label-wrapper">
                        <input type="radio" :name="item" :value="item" v-model="category" />
                        <div class="checkbox"></div>
                        <span class="title">{{ item }}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="filter-quality-container" v-if="qualityOptions.length">
                  <div :class="['title small', { active: !qualityListIsVisible }]" @click="qualityListIsVisible = !qualityListIsVisible">
                    {{ $t("Quality") }}
                  </div>
                  <div :class="['select-container', { hidden: !qualityListIsVisible }]">
                    <label class="chekbox-label" v-for="(item, i) in qualityOptions" :key="i">
                      <div class="chekbox-label-wrapper">
                        <input type="radio" :name="item" :value="item" v-model="quality" />
                        <div class="checkbox"></div>
                        <span class="title">{{ item }}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <div class="button dark" @click="resetFilters">{{ $t("Reset") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="['products-container', { active: !filtersIsVisible }]">
            <div class="sort-checkbox-list">
              <div class="desc">{{ $t("Sort") }}:</div>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="desc" value="desc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">{{ $t("Highest price first") }}</span>
                </div>
              </label>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="asc" value="asc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">{{ $t("Lowest price first") }}</span>
                </div>
              </label>
            </div>
            <transition name="fade">
              <div class="list products" v-if="productList">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
                </div>
              </div>
            </transition>
          </div>
          <pagination :per-page="18" v-if="totalProducts" v-model="activePage" :records="totalProducts" @paginate="changePage"/>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import {mapGetters} from "vuex";

export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem"],
  components: {
    Pagination,
    ProductCard,
  },
  data: function () {
    return {
      imgDomain: "",
      filtersIsVisible: true,
      sort: "desc",
      category: "",
      quality: "",
      activePage: 1,
      totalProducts: null,
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 200000,
      productList: {},
      typeListIsVisible: true,
      qualityListIsVisible: false,
      priceListIsVisible: true,
      specialCategory: "",
    };
  },
  watch: {
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    sort() {
      this.activePage = 1;
      this.filterProducts();
    },
    quality() {
      this.activePage = 1;
      this.filterProducts();
    },
    category() {
      this.activePage = 1;
      this.filterProducts();
    },
    $route() {
      this.activePage = 1;
      this.checkRouteParams();
    },
  },
  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
  },
  methods: {
    checkRouteParams() {
      if (this.$route.params.type == "revolution-collection") {
        this.specialCategory = this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type != "all") {
        this.category = this.$route.params.type;
        this.specialCategory = "";
      } else if (this.$route.params.type && this.$route.params.type === "all") {
        this.category = "";
        this.specialCategory = "";
      }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All quality";
      this.category = "All types";
      this.barMinValue = 0;
      this.barMaxValue = 1200;
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let addCategory = "";
      if (this.category != "All types") {
        addCategory = "type=" + this.category;
      } else {
        addCategory = "type=";
      }

      let addQuality = "";
      if (this.quality != "All quality") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }
      let addPrice = "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;

      let addSort = "&sort=" + this.sort;

      let addPage = "&page=" + this.activePage;

      this.$http
        .get(process.env.VUE_APP_API + "items/list?" + addCategory + addQuality + addPrice + addSort + addPage + "&limit=18" + "&category=" + this.specialCategory + '&currency=' + this.currency)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.pages = res.data.meta.total;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          newCategories.unshift("All types", 'cases');
          this.newCategories = newCategories;
          this.categoryOptions = newCategories;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item) {
      this.$emit("addToCart", item.id, item.item_type);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol', 'currency'])
  }
};
</script>

<template>
  <main class="main profile-page sell-offers-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-wrapper">
            <div class="profile-nav">
              <div class="nav__item">
                <router-link to="/profile">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.83" height="18.354" viewBox="0 0 13.83 18.354">
                    <path id="user" d="M102.751,11.395A4.034,4.034,0,1,1,98.717,7.36a4.034,4.034,0,0,1,4.034,4.035Zm-4.034,5.228a8.22,8.22,0,0,0-6.073,2.67,3.209,3.209,0,0,0,.686,4.9,10.3,10.3,0,0,0,10.775,0,3.209,3.209,0,0,0,.686-4.9A8.22,8.22,0,0,0,98.717,16.622Z" transform="translate(-91.802 -7.36)" fill="#9898a9"/>
                  </svg>
                  {{ $t("My profile") }}
                </router-link>
              </div>
              <div class="nav__item">
                <router-link to="/sell-offers">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.104" height="17.143" viewBox="0 0 16.104 17.143">
                    <g id="history" transform="translate(-15.52)">
                      <path id="Контур_241" data-name="Контур 241" d="M23.323,13.556A4.714,4.714,0,0,1,30.6,9.6c.005-.349.008-.722.008-1.124a42.413,42.413,0,0,0-.209-4.695h.02a4,4,0,0,0-2.6-3.343L27.7.4a4,4,0,0,0-.856-.186l-.028,0A22.576,22.576,0,0,0,23.182,0a25.47,25.47,0,0,0-3.835.207l-.028,0a3.99,3.99,0,0,0-.9.2V.4A4,4,0,0,0,15.73,3.77a42.438,42.438,0,0,0-.21,4.7,42.438,42.438,0,0,0,.21,4.7A4,4,0,0,0,19.3,16.744l.028,0a22.3,22.3,0,0,0,3.635.207c.777,0,1.339-.01,1.781-.028a4.7,4.7,0,0,1-1.419-3.37Zm-3.917-9.1h7.309a.8.8,0,1,1,0,1.6H19.408a.8.8,0,0,1,0-1.6Zm0,5.008a.8.8,0,1,1,0-1.6h4.169a.8.8,0,1,1,0,1.6Z" transform="translate(0 0)" fill="#9898a9"/>
                      <path id="Контур_242" data-name="Контур 242" d="M285.807,297.74a3.587,3.587,0,1,0,3.587,3.587A3.587,3.587,0,0,0,285.807,297.74Zm1.422,4.112h-1.493a.427.427,0,0,1-.427-.427v-2.294a.427.427,0,1,1,.854,0V301h1.066a.427.427,0,1,1,0,.854Z" transform="translate(-257.77 -287.771)" fill="#9898a9"/>
                    </g>
                  </svg>
                  {{ $t("Order history") }}
                </router-link>
              </div>
              <div class="nav__item">
                <a @click="$parent.logout">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="exit" d="M5.7,2A3.2,3.2,0,0,0,2.5,5.2v4h8.469L9.134,7.366a.8.8,0,0,1,1.131-1.131l3.2,3.2.006.006A.8.8,0,0,1,13.7,10v0a.8.8,0,0,1-.229.558l-.006.006-3.2,3.2a.8.8,0,0,1-1.131-1.131L10.969,10.8H2.5v4A3.2,3.2,0,0,0,5.7,18h9.6a3.2,3.2,0,0,0,3.2-3.2V5.2A3.2,3.2,0,0,0,15.3,2Z" transform="translate(-2.5 -2)" fill="#9898a9"/>
                  </svg>
                  <span>{{ $t("LogOut") }}</span>
                </a>
              </div>
            </div>
            <div class="profile-table" v-if="$parent.orderHistory">
              <div class="profile-table-wrapper profile-form-wrapper">
                <div class="title">{{ $t("Your Orders") }}</div>
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <td>{{ $t("ID") }}</td>
                        <td>{{ $t("Date/time") }}</td>
                        <td>{{ $t("Skin name") }}</td>
                        <td>{{ $t("Total") }}</td>
                        <td>{{ $t("Status") }}</td>
                        <td>{{ $t("Details") }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                        <td>{{ item.id }}</td>
                        <td>{{ item.created_at | moment('DD.MM.YYYY HH:mm') }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.sum }} {{ item.currency }}</td>
                        <td>{{ $t(item.status) }}</td>
                        <td>
                          <a class="offer-modal-open" @click="$emit('openOfferDetailsModal', item)">View</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pagination" id="pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "SellOffers",
  props: [],
  components: {},
  data: function () {
    return {};
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit("openOfferDetailsModal");
    },
  },
};
</script>

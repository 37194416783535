<template>
  <div class="menu">
    <ul class="nav">
      <template v-for="(item, i) in $parent.nav">
        <li class="nav__item" :key="i">
          <router-link :to="item.link">{{ $t(item.title) }}</router-link>
        </li>
      </template>
      <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->

      <div class="select-container currency-select-container language-select-container">
        <Select2 v-if="currencies.length > 1" class="select2 single currency-select" v-model="curr" @select="selectCurrency($event)"  :options="currencies" :placeholder="curr" />
      </div>

      <div class="select-container language-select-container" v-if="currentLanguage">
        <Select2 v-if="languages.length > 1" class="select2 single currency-select" v-model="language" @select="selectLanguage($event)" :options="languages" :placeholder="currentLanguageName" />
      </div>
    </ul>
  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/

import Select2 from "vue3-select2-component";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "Menu",
  props: [],
  components: {
    Select2,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapState("app", ["currencies", "currency"]),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
    curr: {
      set(currency) {
        this.setCurrency(currency)
      },
      get() {
        return this.currency;
      }
    }
  },
  methods: {
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    ...mapMutations("app", ["setCurrentLanguage", 'setCurrency']),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>

<template>
  <div class="item-wrapper">
    <div v-if="hasActiveDiscount" class="discount-badge">
      <img src="@/assets/discount.svg" alt="Discount" class="discount-icon">
    </div>
    <div v-if="tag" class="tag">
      <span>{{ tag }}</span>
    </div>
    <div class="preview">
      <img @click="goToSliderItem(item)" :src="getImageUrl(item)" class="img" />
      <transition name="fade">
        <span class="title" v-if="addToCartChosenItem == item.id">{{ $t("Added") }}</span>
      </transition>
    </div>
    <a @click="goToSliderItem(item)" class="desc">{{ getTitle(item) }}</a>
    <div class="price title">
      <span class="discount" v-if="item.old_price && item.old_price != '0.00'">{{ item.old_price }} <span
          class="currency">{{ currencySymbol }}</span></span>
      <span>{{ item.price }} <span class="currency">{{ currencySymbol }}</span></span>
    </div>
    <!-- <div class="desc">{{item.type}}</div> -->
    <a @click="$emit('addToCart', item)" class="link button">
      <span>{{ $t("Add to cart") }}</span>
      <img src="./../assets/cart.svg" class="img" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductCard",
  props: ["item", "addToCartChosenItem", "sliderIsClickble", "tag", "currency"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  watch: {
  },
  methods: {
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      return item.item_type == 'pack' ? item.img_url : this.imgDomain + item.img_url;
    },
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
    ...mapGetters('discount', ['hasActiveDiscount']),
  }
};
</script>
<style scoped lang="scss">
.item-wrapper {
  .discount-badge {
    position: absolute;
    top: 4px;
    right: 10px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    z-index: 1;
  }

  .discount-icon {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

}
</style>
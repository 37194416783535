<template>
  <div class="modal offer-modal">
    <div class="overlay" @click="$emit('closeOfferDetailsModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeOfferDetailsModal')" src="./../assets/close.svg" />
      <div class="container">
        <form>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">{{ $t("Order") }} #{{ $parent.orderToView.id }}</div>
              <div class="form-fields-wrapper">
                <div class="offer-wrapper">
                  <div class="row">
                    <div class="title">{{ $t("Skin name") }}</div>
                    <div class="desc">{{ $parent.orderToView.title }}</div>
                  </div>
                  <div class="row">
                    <div class="title">{{ $t("Quality") }}</div>
                    <div class="desc">
                      {{ $parent.orderToView.item_quality }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="title">{{ $t("Item count") }}</div>
                    <div class="desc">{{ $parent.orderToView.count }}</div>
                  </div>
                  <div class="row">
                    <div class="title">{{ $t("Price") }}</div>
                    <div class="desc">{{ $parent.orderToView.sum }} {{ $parent.currency }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferDetailsModal",
  props: [],
  components: {},
  data: function () {
    return {};
  },
  methods: {
    closeOfferDetailsModal() {
      this.$emit("closeOfferDetailsModal");
    },
  },
};
</script>

<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container">
        <img src="./../assets/close.svg" @click="$parent.closeOrderModal" class="close" />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods && paymentMethods.length">
            <div class="img-btn-container" v-for="(item, i) in paymentMethods" :key="i">
              <button class="img-btn" @click="submit(item.code)">
                <div class="logo-wrapper">
                  <img :src="item.image" class="img" />
                </div>
                {{ item.title }}
              </button>
            </div>
          </div>

          <div v-else class="desc">{{ $t("There are no payment methods available") }}</div>

          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: 'OrderModal',
  props: ['orderData'],
  data: function () {
    return {

    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters('app', ['currency']),
    paymentMethods() {
      return this.$parent.availablePaymentMethods;
    }
  }
}
</script>

<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="product">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title" v-if="addToCartChosenItem === product.id">Added</span>
                    </transition>
                    <img :src="getImageUrl" class="img" />
                  </div>
                  <div class="text">
                    <div class="desc">
                      {{ product.item_type == 'pack' ? 'Pack' : product.type }}
                    </div>
                    <div class="title">
                      {{ product.name || product.title }}
                    </div>
                    <div class="desc" v-if="product.content_desc && product.item_type == 'pack'"
                      v-html="product.content_desc">
                    </div>
                    <div class="price title">
                      <span class="old-price discount" v-if="product.old_price && product.old_price != '0.00'">{{
              product.old_price }}
                        <span class="currency">{{ currencySymbol }}</span></span>
                      <span class="current-price">{{ product.price }} <span class="currency">{{ currencySymbol }}</span></span>
                    </div>
                    <button class="button" @click="addToCart">
                      <span>{{ $t("Add to cart") }}</span>
                    </button>

                    <div class="disclaimer" v-if="product.item_type == 'pack'">
                      <img src="@/assets/tooltip.svg" alt="">
                      <p>
                        Please be advised that items obtained from these cases are distributed on a purely random basis.
                        The value of the items you receive can vary significantly, generally ranging from equal to the
                        cost of the case itself to potentially slightly exceeding the case's value. It's important to
                        understand that due to the random nature of item distribution, we cannot guarantee the exact
                        value or specific items you will receive from any given case. By purchasing a case, you
                        acknowledge and accept these terms and conditions of the platform. If you have any questions or
                        concerns, please contact our customer support before making a purchase.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductPage",
  props: ["addToCartChosenItem"],
  components: {},
  data() {
    return {
      product: null,
    };
  },
  mounted() {
    this.fetchProduct();
  },
  methods: {
    addToCart() {
      if (this.product.item_type === 'pack') {
        this.$emit('addToCart', this.product.id, 'pack');
      }
      else {
        this.$emit('addToCart', this.product.id, 'item');
      }
    },
    fetchProduct() {
      this.product = null;
      const productIdOrSlug = this.$route.params.id || this.$route.params.slug;
      const apiEndpoint = this.getApiEndpoint(productIdOrSlug);

      this.$http
        .get(apiEndpoint, { params: { currency: this.currency } })
        .then((res) => {
          this.product = res.data.payload;
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          this.$router.push({ name: "PageNotFound" });
        });
    },
    getApiEndpoint(productIdOrSlug) {
      if (this.$route.path.includes('/packs/')) {
        return `${process.env.VUE_APP_API}packs/${productIdOrSlug}`;
      } else {
        return `${process.env.VUE_APP_API}items/${productIdOrSlug}`;
      }
    },
  },
  watch: {
    currency() {
      this.fetchProduct();
    },
    $route(to, from) {
      if (to.params.id !== from.params.id || to.params.slug !== from.params.slug) {
        this.fetchProduct();
      }
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol', 'currency']),
    getImageUrl() {
      if (this.product && this.product.item_type === 'pack') {
        return this.product.image_url
      }
      else {
        return this.$parent.imgDomain + this.product.img_url;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.title.price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.current-price,
.old-price {
  position: relative;
  top: 0;
  text-wrap: nowrap;
  position: relative;
  display: inline-block;
}

.old-price {
  color: #999;
}

.old-price:after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #FF4766;
}

.disclaimer {
  color: white;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
  font-family: 'Noto sans', sans-serif;

  h2 {
    font-size: 14px;
  }
}
</style>
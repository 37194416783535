import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import country from './modules/country'
import discount from './modules/discount'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        app,
        country,
        discount,
    },
    strict: debug,
})